import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageContentContainer,
  // ScreenPosition,
} from "components/SelectedScreen/style"
import { NavBar } from "components/NavBar"
import { CoverScreen } from "../components/CoverScreen"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <PageContentContainer cover paginationHidden>
        <NavBar cover />
        {/* <ScreenPosition cover> */}
        <CoverScreen />
        {/* </ScreenPosition> */}
      </PageContentContainer>
    </Layout>
  )
}

export default IndexPage
